import { gql } from '@apollo/client';

const GET_USER = gql`
  query getCompany {
    data
      @rest(
        type: "User"
        path: "/operator-view/accounts-legacy/current"
        method: "GET"
      ) {
      id
      displayName
      email
      createdAt
      role
      isRoot
      company @type(name: "company") {
        id
        name
        createdAt
        downtimeUnderlayDelay: downtime_underlay_delay
        enableOperatorTracking: enable_operator_tracking
        enableTabletMachineSwitching: enable_tablet_machine_switching
        hideRemoveRejectedPartsCheckbox: hide_remove_rejected_parts_checkbox
        hideCategorizationClose: hidecategorizationclose
        productTier: product_tier
        warningPercentUtilization: warning_percent_utilization
        failurePercentUtilization: failure_percent_utilization
        defaultDashboardMetric: default_dashboard_metric
        timezone
        onboardedAt: onboarded_at
        trialExpirationDate: trial_expiration_date
        downtimeListFilterDuration: downtime_list_filter_duration
        microstopDuration: microstop_duration
        locale
        enableSetupStages: enable_setup_stages
        cycleInputOption: cycleinputoption
        showInitialDowntimePrompt: show_initial_downtime_prompt
        showDowntimeCategorizationFlyup: show_downtime_categorization_flyup
        downtimeFlyupDelay: downtime_flyup_delay
        enableOpdashWorkOrder: enable_opdash_work_order
        isToolMonitoringEnabled: tool_monitoring_enabled
        isOperatorRunAutoSignoutEnabled: operator_run_auto_signout_enabled
        currentTimeContext: currentTimeContext {
          offsetMs: offset_millis
        }
      }
    }
  }
`;

const GET_LOCATION_REF = gql`
  query getLocationRef {
    locations {
      locationRef
    }
  }
`;

const GET_NATS_TOKEN = gql`
  query getNatsToken {
    token(companyId: $companyId)
      @rest(type: "NATS", path: "/operator-view/nats-token", method: "GET")
  }
`;

const GET_LABS_JWT = gql`
  query getLabsJwt {
    token
      @rest(type: "labs", path: "/operator-view/mm-labs/token", method: "GET") {
      token
    }
  }
`;

// TODO: until GraphQl Machine View is ready to be used,
// we use the REST api for now
// const GET_MACHINE = gql`
//   query getMachine($machineId: String) {
//     Machine(where: { machineId: { _eq: $machineId } }) {
//       autostartBehavior
//       downtimeFailureDelay
//       machineId
//       machineRef
//       name
//       utilizationFailurePercent
//       utilizationWarningPercent
//     }
//   }
// `;

const GET_MACHINE = gql`
  query getMachine($machineId: String) {
    getMachine(machineId: $machineId)
      @rest(
        type: "Machine"
        path: "/operator-view/temporary-v2/machines/{args.machineId}"
        method: "GET"
      ) {
      agentId
      autostartBehavior
      downtimeFailureDelay
      machineId: id
      machineRef
      name
      utilizationFailurePercent
      utilizationWarningPercent
      operatorCreateOperation
      partOperationFingerprint
      programMetric
    }
  }
`;

const GET_AGENT_STATUS = gql`
  query getAgentStatus($agentId: String) {
    agentStatus(agentId: $agentId)
      @rest(
        type: "AgentStatus"
        path: "/operator-view/agent/{args.agentId}/status"
        method: "GET"
      ) {
      connected
    }
  }
`;

const GET_PART_ADJUSTMENTS = gql`
  query getPartAdjustments(
    $machineId: String
    $start: timestamp
    $end: timestamp
  ) {
    getPartAdjustments(machineId: $machineId, start: $start, end: $end)
      @rest(
        type: "PartAdjustment"
        path: "/operator-view/part-adjustments?machine_id={args.machineId}&start={args.start}&end={args.end}"
        method: "GET"
      ) {
      id
      message
      adjustmenttime
      scrapcount
      count
      dataitemname
      scrap
      jobName
    }
  }
`;

const GET_JOB_RUN = gql`
  query getJobRun($machineId: String) {
    jobRun(machineId: $machineId)
      @rest(
        type: "JobRun"
        path: "/operator-view/job/current/{args.machineId}"
        method: "GET"
      ) {
      actualParts
      endDate
      expectedPartTime
      failurePercent
      idealPartTime
      jobDisplayName
      jobId
      jobRunId
      laborActivitySetId
      partCountMultiplier
      quantity
      resetDate
      setupEnd
      setupTime
      startDate
      warningPercent
      laborActivitySetId
      programName
    }
  }
`;

const GET_INITIAL_DATA = gql`
  query getInitialData($machineRef: Int, $startDate: timestamptz) {
    executionIntervals: stateIntervals(
      args: {
        machineRef: $machineRef
        windowStartAt: $startDate
        metricKey: "MasterExecution"
      }
      order_by: { endAt: desc_nulls_first }
    ) {
      start: startAt
      end: endAt
      label: value
    }
    alarms: currentAlarmsByMachine(
      args: { machineRef: $machineRef }
      where: { metricKey: { _neq: "MasterCondition" } }
      order_by: { startAt: desc }
    ) {
      start: startAt
      label
      description
    }
    machinePartKey: machines(where: { machineRef: { _eq: $machineRef } }) {
      partCount: partCountMetricKey
    }
    metricPartKeys: metrics(
      where: {
        machineRef: { _eq: $machineRef }
        type: { _eq: "PartCount" }
        subtype: { _eq: "ALL" }
      }
    ) {
      key: metricKey
    }
  }
`;

const SUBSCRIBE_FIRST_PART = gql`
  subscription subscribeFirstPart($machineRef: Int, $startDate: timestamptz) {
    firstPart: partCounts(
      args: { machineRef: $machineRef, windowStartAt: $startDate }
      order_by: { eventTime: asc }
      limit: 1
    ) {
      key: metricKey
      eventTime
    }
  }
`;

const SUBSCRIBE_CURRENT_ALARMS = gql`
  subscription subscribeCurrentAlarms($machineRef: Int) {
    alarms: currentAlarmsByMachine(
      args: { machineRef: $machineRef }
      where: {
        metricKey: { _neq: "MasterCondition" }
        label: { _in: ["FAULT", "WARNING"] }
      }
      order_by: { startAt: desc }
    ) {
      start: startAt
      label
      description
    }
  }
`;

const generateAlarmSubscriptionQuery = ({ endDateIsNull, description }) => {
  return gql`
    subscription subscribeAlarmIntervals(
      $label: [String!]
      $machineRef: Int
      $sortByDate: order_by
      $sortByDescription: order_by
      ${description !== undefined ? '$description: String' : ''}
      ${endDateIsNull !== undefined ? '$endDateIsNull: Boolean' : ''}
      $limit: Int
      $windowStart: timestamptz

    ) {
      alarmIntervals(
        args: { machineRef: $machineRef, windowStartAt: $windowStart }
        where: {
          metricKey: { _neq: "MasterCondition" }
          label: { _in: $label }
          ${
            endDateIsNull !== undefined
              ? 'endAt: { _is_null: $endDateIsNull }'
              : ''
          }
        ${
          description !== undefined
            ? 'description: { _ilike: $description }'
            : ''
        }
        }
        order_by: {
          endAt: $sortByDate
          startAt: $sortByDate
          description: $sortByDescription
        }
        limit: $limit
      ) {
        start: startAt
        end: endAt
        label
        description
        sequence
      }
    }
  `;
};

const SUBSCRIBE_ALARM_INTERVALS = gql`
  subscription subscribeAlarmIntervals(
    $label: [String!]
    $machineRef: Int
    $sortByDate: order_by
    $sortByDescription: order_by
    $description: String
    $endDateIsNull: Boolean
    $limit: Int
    $windowStart: timestamptz
  ) {
    alarmIntervals(
      args: { machineRef: $machineRef, windowStartAt: $windowStart }
      where: {
        metricKey: { _neq: "MasterCondition" }
        endAt: { _is_null: $endDateIsNull }
        label: { _in: $label }
        description: { _ilike: $description }
      }
      order_by: {
        endAt: $sortByDate
        startAt: $sortByDate
        description: $sortByDescription
      }
      limit: $limit
    ) {
      start: startAt
      end: endAt
      label
      description
      sequence
    }
  }
`;

const SUBSCRIBE_EXECUTION_INTERVALS = gql`
  subscription subscribeStatusIntervals(
    $machineRef: Int
    $startDate: timestamptz
  ) {
    executionIntervals: stateIntervals(
      args: {
        machineRef: $machineRef
        windowStartAt: $startDate
        metricKey: "MasterExecution"
      }
      order_by: { endAt: desc_nulls_first }
    ) {
      start: startAt
      end: endAt
      label: value
    }
  }
`;

// aggregatedCountsByActivitySet was created specifically for opDash and is ok to use as of 9/20/22 - TabithaMaudMM
const GET_PART_BUCKETS_BY_WINDOW = gql`
  query queryPartCountBuckets(
    $machineRef: Int
    $startDate: timestamptz
    $intervals: _tstzrange
    $partKeys: [String!]
    $bucketWidth: String
  ) {
    aggregatedCountsByActivitySet(
      args: {
        machineRef: $machineRef
        windowStart: $startDate
        bucketWidth: $bucketWidth
        intervals: $intervals
      }
      where: { metricKey: { _in: $partKeys } }
    ) {
      sum
      count
      bucket
    }
  }
`;

// aggregatedCountsByActivitySet was created specifically for opDash and is ok to use as of 9/20/22 - TabithaMaudMM
const GET_PART_BUCKETS_PARTITIONED_BY_SCHEDULED = gql`
  query queryPartCountBuckets(
    $machineRef: Int
    $startDate: timestamptz
    $scheduledIntervals: _tstzrange
    $unscheduledIntervals: _tstzrange
    $partKeys: [String!]
    $bucketWidth: String
  ) {
    scheduledParts: aggregatedCountsByActivitySet(
      args: {
        machineRef: $machineRef
        windowStart: $startDate
        bucketWidth: $bucketWidth
        intervals: $scheduledIntervals
      }
      where: { metricKey: { _in: $partKeys } }
    ) {
      sum
      count
      bucket
    }
    unscheduledParts: aggregatedCountsByActivitySet(
      args: {
        machineRef: $machineRef
        windowStart: $startDate
        bucketWidth: $bucketWidth
        intervals: $unscheduledIntervals
      }
      where: { metricKey: { _in: $partKeys } }
    ) {
      sum
      count
      bucket
    }
  }
`;

const SUBSCRIBE_MINIMUM_PART_COUNT_EVENTS = gql`
  subscription subscribeMinimumPartCountEvents(
    $machineRef: Int
    $startDate: timestamptz
  ) {
    partCounts(
      args: { machineRef: $machineRef, windowStartAt: $startDate }
      order_by: { eventTime: desc }
      limit: 5
    ) {
      eventTime
      value
    }
  }
`;

const SUBSCRIBE_CURRENT_EXECUTION_ALL_MACHINES = gql`
  subscription subscribeCurrentExecutionAllMachines {
    currentExecution: machines(
      where: {
        currentStates: { metricKey: { _eq: "MasterExecution" } }
        decommissionedAt: { _is_null: true }
      }
    ) {
      machineRef
      name
      currentStates(where: { metricKey: { _eq: "MasterExecution" } }) {
        start: startAt
        value
      }
    }
  }
`;

const SUBSCRIBE_ALARMS = gql`
  subscription {
    machines(where: { currentAlarms: { label: { _eq: "FAULT" } } }) {
      machineRef
      name
      currentAlarms(where: { label: { _eq: "FAULT" } }) {
        machineRef
        nativeCode
        value: label
        start: startAt
      }
    }
  }
`;
const SUBSCRIBE_ACTIVITIES_STATUS = gql`
  subscription subscribeActivitiesStatus {
    Company {
      activitiesStatus
    }
  }
`;

const GET_DASHBOARD_MACHINE_DATA = gql`
  query getDashboardMachineData {
    dashboardMachineData
      @rest(
        type: "Dashboard"
        path: "/operator-view/dashboard"
        method: "GET"
      ) {
      machines {
        id
        setupSince
        pausedJobName
        machineRef
      }
    }
  }
`;

const SUBSCRIBE_LATEST_HEARTBEAT = gql`
  subscription subscribeLatestHeartbeat($machineRef: Int) {
    latestHeartbeat: currentStatesByMachine(
      args: { machineRef: $machineRef, metricKey: "mmheartbeat" }
    ) {
      start: startAt
      eventTime: latestEventAt
      value
    }
  }
`;

const SUBSCRIBE_PROGRAM_NAMES = gql`
  subscription subscribeProgramNames($machineRef: Int, $metricKey: String) {
    programNames: currentStatesByMachine(
      args: { metricKey: $metricKey, machineRef: $machineRef }
      limit: 1
    ) {
      value
    }
  }
`;

const GET_JOBS = gql`
  query getJobInfo($machineId: String, $filter: String, $limit: Int) {
    jobs(machineId: $machineId, limit: $limit, filter: $filter)
      @rest(
        type: "JobPayload"
        path: "/operator-view/jobs?limit={args.limit}&machineId={args.machineId}&filter={args.filter}"
        method: "GET"
      ) {
      count
      rows @type(name: "Job") {
        jobId: id
        lot
        name
        operation
        part
        actualParts
        createdAt
        description
        displayName
        dueAt: due_at
        expectedPartTime: expectedCycleTime
        failurePercent: failurepercent
        hidden
        idealPartTime: idealCycleTime
        jobMachines: job_machines {
          jobMachineId: id
          actualParts
          createdAt
          expectedPartTime: expectedCycleTime
          idealPartTime: idealCycleTime
          jobId: job_id
          machineId: machine_id
          partCountMultiplier
          programName: programname
          setupTime: setuptime
          updatedAt
        }
        machineGroupAssociation: machine_group_association
        partCountMultiplier
        partValue: partvalue
        programName: programname
        quantity
        setupTime: setuptime
        updatedAt
        warningPercent: warningpercent
        optimalUnitDurationMs: optimal_unit_duration_ms
      }
    }
  }
`;

const GET_DOWNTIME = gql`
  query getDowntime(
    $limit: Int
    $machineRef: Int
    $windowStart: timestamptz
    $nonIdleWindowStart: timestamptz
  ) {
    downtimes: stateIntervals(
      args: {
        machineRef: $machineRef
        windowStartAt: $windowStart
        metricKey: "MasterExecution"
      }

      where: { value: { _neq: "ACTIVE" } }
      limit: $limit
      order_by: { endAt: asc_nulls_last }
    ) {
      start: startAt
      end: endAt
      label: value
    }
    lastNonIdleInterval: stateIntervals(
      args: {
        machineRef: $machineRef
        windowStartAt: $nonIdleWindowStart
        metricKey: "MasterExecution"
      }

      where: { value: { _in: ["ACTIVE", "UNAVAILABLE"] } }
      order_by: { endAt: desc_nulls_first }
      limit: 1
    ) {
      label: value
      end: endAt
    }
  }
`;

const GET_RECENT_OPERATOR_RUNS = gql`
  query getRecentOperatorRuns($machineRef: Int, $limit: Int) {
    operatorRuns(machineRef: $machineRef, limit: $limit)
      @rest(
        type: "OperatorRun"
        path: "/operator-view/machines/{args.machineRef}/operator-runs/recent?limit={args.limit}"
        method: "GET"
      ) {
      operatorRuns @type(name: "OperatorRun") {
        id: operatorRunId
        startAt
        endAt
        companyId
        operatorId
        machineId
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_OPERATORS = gql`
  query operators {
    Operator
      @rest(type: "Operators", path: "/operator-view/operator", method: "GET") {
      archivedAt
      name
      id
      erpId
    }
  }
`;

const GET_CURRENT_SHIFT_LEGACY = gql`
  query getRecentShiftRunsLegacy($machineRef: Int) {
    shifts(machineRef: $machineRef)
      @rest(
        type: "Shifts"
        path: "/operator-view/machines/{args.machineRef}/shifts-legacy/current"
        method: "GET"
      ) {
      id
      name
      start
      end
      optional
    }
  }
`;

const GET_ANNOTATION_TYPES = gql`
  query getAnnotationTypes($machineId: String) {
    AnnotationType(machineId: $machineId)
      @rest(
        type: "AnnotationTypes"
        path: "/operator-view/annotation-types?machineId={args.machineId}"
        method: "GET"
      ) {
      id
      name
      color
      plannedBehavior
    }
  }
`;

const GET_PART_ADJUSTMENT_TYPES = gql`
  query getPartAdjustmentTypes {
    PartAdjustmentType
      @rest(
        type: "PartAdjustmentTypes"
        path: "/operator-view/part-adjustment-types?companyOnly=true"
        method: "GET"
      ) {
      name
      color
      id
      rejectBehavior: reject_behavior
    }
  }
`;

const GET_JOB_QUANTITY = gql`
  query getJobQuantity($jobId: String) {
    jobQuantity(jobId: $jobId)
      @rest(
        type: "JobQuantity"
        path: "/operator-view/jobs/{args.jobId}/quantity"
        method: "GET"
      ) {
      quantity: good_parts
    }
  }
`;

const GET_ACTIVITY_TYPES = gql`
  query getActivityTypes {
    activityTypes
      @rest(
        type: "ActivityType"
        path: "/operator-view/activity-types"
        method: "GET"
      ) {
      activityTypes @type(name: "ActivityTypes") {
        activityTypeRef
        companyId
        activityMode
        name
        impactsAvailability
        impactsUnitCount
        updatedAt
        archivedAt
      }
    }
  }
`;

const GET_RECENT_ACTIVITY_SETS = gql`
  query getRecentActivitySets(
    $machineRef: Int
    $includeJobData: Boolean
    $limit: Int
  ) {
    activitySets(
      machineRef: $machineRef
      includeJobData: $includeJobData
      limit: $limit
    )
      @rest(
        type: "ActivitySet"
        path: "/operator-view/machines/{args.machineRef}/activity-sets/recent?includeJobData={args.includeJobData}&limit={args.limit}"
        method: "GET"
      ) {
      activitySets @type(name: "ActivitySet") {
        activitySetRef
        companyId
        machineRef
        closedAt
        updatedAt
        createdAt
        expectedSetupDuration
        idealUnitDuration
        expectedUnitDuration
        optimalUnitDuration
        idealMetricDuration
        jobId
        partCountMultiplier
        actualParts
        workOrderId
        workOrderOperationRef
        activities @type(name: "Activity") {
          activityRef
          activitySetRef
          activityTypeRef
          start: startAt
          end: endAt
          updatedAt
          jobScheduleGroupId
        }
        job @type(name: "Job") {
          jobId: id
          lot: lotName
          name: jobName
          operation: operationName
          part: partName
          actualParts
          createdAt
          description
          displayName
          dueAt
          expectedPartTime
          failurePercent
          hidden
          idealPartTime
          machineGroupAssociation: associatedMachineGroup
          partCountMultiplier
          partValue
          programName
          quantity
          setupTime: expectedSetupTime
          updatedAt
          warningPercent
          optimalUnitDurationMs
        }
      }
    }
  }
`;

const GET_WORKFLOWS = gql`
  query getMonitor {
    data
      @rest(
        type: "Workflow"
        path: "/operator-view/monitors?triggerType=manual"
        method: "GET"
      ) {
      id
      machineId
      name
      arguments
      evaluation
      evaluationType
      monitorState
      triggerType
      monitorType
      instructionsId
      shifts
    }
  }
`;

const GET_TAB_CONFIG = gql`
  query getTabConfig {
    data
      @rest(
        type: "Template"
        path: "/operator-view/templates?namespace=opDashTabs"
        method: "GET"
      ) {
      templates
    }
  }
`;

const GET_MACHINE_GROUPS = gql`
  query getMonitor {
    data
      @rest(
        type: "Workflow"
        path: "/operator-view/machine-groups"
        method: "GET"
      ) {
      id
      name
      erpGroupId
      includeAllAnnotationTypes
      machines
      downtimeReasons
    }
  }
`;

// workOrderOperation's startQuantity is actually currentQuantity, currently we do not have any insight for the erp _start quantity_.
// Renaming this variable to currentQuantity avoid confusion on the frontend
const GET_WORK_ORDER_OPERATIONS = gql`
  query getWorkOrderOperations(
    $where: erpWorkOrderOperations_bool_exp
    $limit: Int
  ) {
    erpWorkOrderOperations(
      limit: $limit
      order_by: { scheduledStartDate: asc }
      where: $where
    ) {
      closedDate
      finishQuantity
      productionburdenRateHourly
      workOrderOperationRef
      workOrderId
      resourceId
      scheduledFinishDate
      scheduledStartDate
      setupTimeMs
      currentQuantity: startQuantity
      status
      sequenceNumber
      lot
      sub
      split
      cycleTimeMs
      matchingPartOperations {
        cycleTimeMs
        description
        partName
        quantityPerPart
        setupTimeMs
        partOperationRef
        partNumber
        sequenceNumber
        description
      }
      workOrder {
        partNumber
        lot
        workOrderId
        sub
        split
        dueDate
      }
      resource {
        machineRef
        resourceId
        isResourceGroup
      }
    }
  }
`;

// workOrderOperation's startQuantity is actually currentQuantity, currently we do not have any insight for the erp _start quantity_.
// Renaming this variable to currentQuantity avoid confusion on the frontend
const GET_SELECTED_WORK_ORDER_OPERATION = gql`
  query getSelectedWorkOrderOperation($workOrderOperationRef: bigint!) {
    erpWorkOrderOperation(workOrderOperationRef: $workOrderOperationRef) {
      closedDate
      finishQuantity
      productionburdenRateHourly
      workOrderOperationRef
      workOrderId
      resourceId
      scheduledFinishDate
      scheduledStartDate
      setupTimeMs
      currentQuantity: startQuantity
      status
      sequenceNumber
      lot
      sub
      split
      cycleTimeMs
      quantityPerPart
      matchingPartOperations {
        cycleTimeMs
        description
        partName
        quantityPerPart
        setupTimeMs
        partOperationRef
        partNumber
        sequenceNumber
        description
      }
      workOrder {
        partNumber
        lot
        workOrderId
        sub
        split
        dueDate
      }
      resource {
        machineRef
        resourceId
        isResourceGroup
      }
    }
  }
`;

const GET_ERP_RESOURCES = gql`
  query getResources($machineRef: Int, $machineGroups: [String!]) {
    erpResources(
      where: {
        _or: [
          { machineRef: { _eq: $machineRef } }
          { machineGroupId: { _in: $machineGroups } }
        ]
      }
    ) {
      name
      machineRef
      machineGroupId
      isResourceGroup
      resourceId
      type
    }
  }
`;

const SUBSCRIBE_LABOR_TICKETS = gql`
  subscription erpLaborTickets($where: erpLaborTickets_bool_exp) {
    erpLaborTickets(where: $where, order_by: { clockIn: desc }) {
      badParts
      clockIn
      clockOut
      goodParts
      laborTicketId
      laborTicketRef
      lot
      personId
      reasons {
        reasonRef
        reason {
          annotationTypeRef
        }
      }
      resource {
        machineRef
        resourceId
      }
      resourceId
      sequenceNumber
      state
      split
      sub
      transactionDate
      type
      workOrderId
    }
  }
`;

const GET_SCHEDULED_TIME = gql`
  query getScheduledTime(
    $machineRef: Int
    $startAt: timestamptz
    $endAt: timestamptz
    $shouldRound: Int
  ) {
    getScheduledTime(
      machineRef: $machineRef
      startAt: $startAt
      endAt: $endAt
      shouldRound: $shouldRound
    )
      @rest(
        type: "ScheduledTime"
        path: "/operator-view/machines/{args.machineRef}/scheduled-time?startAt={args.startAt}&endAt={args.endAt}&shouldRound={args.shouldRound}"
        method: "GET"
      ) {
      stats
      scheduledIntervals
      slicedIntervals
    }
  }
`;

const GET_MACHINE_SCHEDULE = gql`
  query getMachineSchedule {
    machineSchedule(machineId: $machineId)
      @rest(
        type: "WorkOrder"
        path: "/operator-view/production-schedule/{args.machineId}"
        method: "GET"
      ) {
      schedule
    }
  }
`;

const GET_WORK_ORDER_OPERATION_PARTS = gql`
  query getWorkOrderOperationParts($workOrderOperationRef: Int) {
    workOrderOperationParts(workOrderOperationRef: $workOrderOperationRef)
      @rest(
        type: "WorkOrder"
        path: "/operator-view/activities/work-order/{args.workOrderOperationRef}"
        method: "GET"
      ) {
      totalParts
      goodParts
      rejectedParts
      startAt
    }
  }
`;

export {
  GET_ANNOTATION_TYPES,
  GET_DOWNTIME,
  GET_INITIAL_DATA,
  GET_JOBS,
  GET_JOB_RUN,
  GET_JOB_QUANTITY,
  GET_MACHINE,
  GET_AGENT_STATUS,
  GET_OPERATORS,
  GET_PART_ADJUSTMENTS,
  GET_PART_ADJUSTMENT_TYPES,
  GET_RECENT_OPERATOR_RUNS,
  GET_CURRENT_SHIFT_LEGACY,
  GET_USER,
  SUBSCRIBE_CURRENT_ALARMS,
  SUBSCRIBE_ALARM_INTERVALS,
  SUBSCRIBE_EXECUTION_INTERVALS,
  GET_PART_BUCKETS_BY_WINDOW,
  GET_PART_BUCKETS_PARTITIONED_BY_SCHEDULED,
  SUBSCRIBE_MINIMUM_PART_COUNT_EVENTS,
  SUBSCRIBE_CURRENT_EXECUTION_ALL_MACHINES,
  SUBSCRIBE_ALARMS,
  GET_DASHBOARD_MACHINE_DATA,
  SUBSCRIBE_LATEST_HEARTBEAT,
  GET_ACTIVITY_TYPES,
  GET_RECENT_ACTIVITY_SETS,
  SUBSCRIBE_ACTIVITIES_STATUS,
  SUBSCRIBE_PROGRAM_NAMES,
  SUBSCRIBE_FIRST_PART,
  GET_WORKFLOWS,
  GET_TAB_CONFIG,
  GET_LOCATION_REF,
  GET_NATS_TOKEN,
  generateAlarmSubscriptionQuery,
  GET_LABS_JWT,
  GET_MACHINE_GROUPS,
  GET_ERP_RESOURCES,
  GET_SELECTED_WORK_ORDER_OPERATION,
  SUBSCRIBE_LABOR_TICKETS,
  GET_WORK_ORDER_OPERATIONS,
  GET_SCHEDULED_TIME,
  GET_WORK_ORDER_OPERATION_PARTS,
  GET_MACHINE_SCHEDULE,
};
