import { useCallback, useEffect, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import session from 'lib/api/links/session';

import {
  GET_INITIAL_DATA,
  GET_PART_ADJUSTMENTS,
  GET_JOB_QUANTITY,
  GET_RECENT_ACTIVITY_SETS,
  GET_RECENT_OPERATOR_RUNS,
  GET_CURRENT_SHIFT_LEGACY,
  GET_OPERATORS,
  GET_ACTIVITY_TYPES,
  GET_DOWNTIME,
  GET_WORKFLOWS,
  GET_PART_BUCKETS_PARTITIONED_BY_SCHEDULED,
  GET_LABS_JWT,
  GET_AGENT_STATUS,
  GET_MACHINE_GROUPS,
  GET_SELECTED_WORK_ORDER_OPERATION,
  GET_ERP_RESOURCES,
  GET_SCHEDULED_TIME,
  GET_WORK_ORDER_OPERATION_PARTS,
} from 'lib/api/queries';
import { useNetworkError } from 'lib/hooks';
import {
  POLL_INTERVAL,
  ERROR_KEYS,
  LIGHT_UPDATE_INTERVAL,
  THIRTY_SECOND_INTERVAL,
  LAB_REFRESH_DURATION,
  FLAG_KEYS,
  SIXTY_SECOND_INTERVAL,
} from 'lib/constants';
import { getMachine } from 'lib/selectors/getMachine';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import { getScopeEnd } from 'lib/selectors/getScopeEnd';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import {
  actionSetPartAdjustments,
  actionSetInitialData,
  actionSetShiftStatusIntervals,
  actionSetLoading,
  actionUnsetLoading,
  actionSetJobTotal,
  actionSetRecentActivitySets,
  actionSetRecentOperatorRuns,
  actionSetShifts,
  actionSetOperators,
  actionSetActivityTypes,
  actionSetDowntimeIntervals,
  actionSetPartCountBuckets,
  actionSetWorkflows,
  actionSetLabsAuthorized,
  actionSetUtilizationHourlies,
  actionSetAgentStatus,
  actionSetMachineGroups,
  actionSetCurrentWorkOrderOp,
  actionSetLaborTicketScopeAdjustment,
  actionSetScheduledTime,
  actionSetMachineERPResources,
  actionSetScheduledTimeEnabled,
  actionSetCurrentWorkOrderOpGoodParts,
  actionSetLaunchDarkleyFlags,
} from 'lib/actions';
import { getIsLoadingJobRunASWrapper } from 'lib/selectors/getIsLoadingJobRunASWrapper';
import { getIsInitialDataSet } from 'lib/selectors/getIsInitialDataSet';
import { getIsInitialShiftPollLoading } from 'lib/selectors/getIsInitialShiftPollLoading';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getStartOfCurrentShopDay } from 'lib/selectors/getStartOfCurrentShopDay';
import {
  formatIntervalsAsTSRange,
  startOfAppHistory,
  takeEarliest,
} from 'lib/utils/date';
import { getStartOfSixDaysPrior } from 'lib/selectors/getStartOfSixDaysPrior';
import { getPartKeys } from 'lib/selectors/getPartKeys';
import { getLastPartTimestamp } from 'lib/selectors/getLastPartTimestamp';
import { getLatestJobQuantity } from 'lib/selectors/getLatestJobQuantity';
import { getPartCountQueryIntervals } from 'lib/selectors/getPartCountQueryIntervals';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { getLatestOperator } from 'lib/selectors/getLatestOperator';
import { getLatestOperatorRun } from 'lib/selectors/getLatestOperatorRun';
import { getSetupModeStart } from 'lib/selectors/getSetupModeStart';
import { getShiftStart } from 'lib/selectors/getShiftStart';
import {
  getHasAHistoricalPartsTab,
  getHasDowntimeTab,
  getHasPartsGoalTab,
  getHasLabTab,
} from 'lib/selectors/getTabs';
import { LABS_URL } from 'lib/appConfig';
import { bugsnag } from 'lib/external/bugsnag';
import { utilizationSlices } from 'components/charts/utilizationSlices';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import moment from 'moment';
import { getGroupIdsForMachine } from 'lib/selectors/getMachineGroups';
import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';
import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getWorkOrderOpActionLoading } from 'lib/selectors/getWorkOrderOpActionLoading';
import { getLaborTicketActionLoading } from 'lib/selectors/getLaborTicketActionLoading';

const useInitialData = () => {
  const machine = useSelector(getMachine);
  const scopeStart = useSelector(getScopeStart);
  const scopeEnd = useSelector(getScopeEnd);
  const shiftStart = useSelector(getShiftStart);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestJobQuantity = useSelector(getLatestJobQuantity);
  const { jobId } = latestActivitySet;
  const dispatch = useDispatch();
  const runLoading = useSelector(getIsLoadingJobRunASWrapper);
  const isInitialDataSet = useSelector(getIsInitialDataSet);
  const partKeys = useSelector(getPartKeys);
  const lastPartTimestamp = useSelector(getLastPartTimestamp);
  const sixDaysPrior = useSelector(getStartOfSixDaysPrior);
  const partCountQueryIntervals = useSelector(getPartCountQueryIntervals);
  const startOfHistory = startOfAppHistory();
  const setupModeStart = useSelector(getSetupModeStart);
  const isOperatorTrackingEnabled = useSelector(getIsOperatorTrackingEnabled);
  const hasPartsGoalTab = useSelector(getHasPartsGoalTab);
  const hasDowntimeTab = useSelector(getHasDowntimeTab);
  const hasAHistoricalPartsTab = useSelector(getHasAHistoricalPartsTab);
  const hasLabTab = useSelector(getHasLabTab);
  const latestOperator = useSelector(getLatestOperator);
  const latestOperatorRun = useSelector(getLatestOperatorRun);
  const isInitialShiftPollLoading = useSelector(getIsInitialShiftPollLoading);
  const isLatestShiftActive = useSelector(checkIsLatestShiftActive);
  const startOfCurrentShopDay = useSelector(getStartOfCurrentShopDay);
  const isAPMEnabled = useSelector(getIsAPMEnabled);

  const flags = useFlags();

  // there are times we _need_ access to flags in our selectors. Placing them in redux enabled this for us
  useEffect(() => {
    dispatch(actionSetLaunchDarkleyFlags(flags));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [flags]);

  const laborTicketEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];
  const scheduledTimeEnabled = flags[FLAG_KEYS.SCHEDULED_TIME_ENABLED];
  const workOrderFlowEnabled = flags[FLAG_KEYS.WORK_ORDER_FLOW_ENABLED];
  const showWorkOrderFlow = useIsWorkOrderFlow();
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);

  useEffect(() => {
    if (!currentWorkOrderOp && workOrderFlowEnabled) {
      dispatch(actionSetCurrentWorkOrderOp(null));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [workOrderFlowEnabled]);

  const currentLaborTicket = useSelector(getCurrentLaborTicket);

  const LABOR_TICKET_PARTS_OUTSIDESCOPE = gql`
    query queryPartCountBuckets(
      $machineRef: Int
      $windowStart: timestamptz
      $windowEnd: timestamptz
      $partKeys: [String!]
    ) {
      aggregatedCountsByActivitySet(
        args: {
          machineRef: $machineRef
          windowStart: $windowStart
          windowEnd: $windowEnd
        }
        where: { metricKey: { _in: $partKeys } }
      ) {
        sum
        count
      }
    }
  `;

  const laborTicketStartAfterScope =
    currentLaborTicket &&
    moment(currentLaborTicket.clockIn).isAfter(scopeStart);

  const laborTicketStartBeforeScope =
    currentLaborTicket &&
    moment(currentLaborTicket?.clockIn).isBefore(scopeStart);

  const queryOutsideScopeParts =
    (currentLaborTicket && laborTicketStartBeforeScope) ||
    laborTicketStartAfterScope;

  const clockIn = currentLaborTicket?.clockIn;

  const requestLaborTicketPartScopeAdjustment = useQuery(
    LABOR_TICKET_PARTS_OUTSIDESCOPE,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      variables: {
        machineRef: machine.machineRef,
        windowStart: laborTicketStartBeforeScope ? clockIn : scopeStart,
        partKeys,
        windowEnd: laborTicketStartBeforeScope ? scopeStart : clockIn,
      },

      skip:
        !queryOutsideScopeParts ||
        !laborTicketEnabled ||
        !partKeys ||
        isAPMEnabled,
      onCompleted: ({ aggregatedCountsByActivitySet }) => {
        if (aggregatedCountsByActivitySet) {
          const sum = aggregatedCountsByActivitySet.reduce((total, curr) => {
            return total + curr.sum;
          }, 0);
          dispatch(
            actionSetLaborTicketScopeAdjustment(
              sum * (laborTicketStartAfterScope ? -1 : 1)
            )
          );
        }
      },
    }
  );

  useNetworkError(
    requestLaborTicketPartScopeAdjustment,
    ERROR_KEYS.LABOR_TICKET_PART_ADJ
  );

  const { startOfSixDaysPrior, historyStart } = useMemo(() => {
    return {
      startOfSixDaysPrior: sixDaysPrior,
      historyStart: startOfHistory,
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startOfCurrentShopDay, shiftStart, machine.machineRef]);

  // Activity Types
  const activityTypes = useQuery(GET_ACTIVITY_TYPES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      dispatch(actionSetActivityTypes(data.activityTypes));
    },
  });

  useNetworkError(activityTypes, ERROR_KEYS.ACTIVITY_TYPES);

  // Recent Activity Sets
  const requestRecentActivitySets = useQuery(GET_RECENT_ACTIVITY_SETS, {
    variables: {
      machineRef: machine.machineRef,
      includeJobData: true,
      // limiting to 1 for R1, but will support multiple open sets in future
      limit: 1,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !machine.machineRef,
    onCompleted: ({ activitySets }) => {
      if (!activitySets) {
        /*
the main intent here is to handle case where user switches companies
and the req for the old machine is now out of company scope; we clear the
token tied to the old company and redirect the user to the machine switcher so
they can pull a new token for the company they are now switched to
*/
        session.clear();
        window.location = '/switch-machines';
        return;
      }
      dispatch(actionSetRecentActivitySets(activitySets));
    },
  });

  const { refetch: refetchRecentActivitySets } = requestRecentActivitySets;
  useNetworkError(requestRecentActivitySets, ERROR_KEYS.RECENT_ACTIVITY_SETS, {
    pollInterval: POLL_INTERVAL,
  });

  // recent operator runs
  const requestRecentOperatorRuns = useQuery(GET_RECENT_OPERATOR_RUNS, {
    variables: {
      machineRef: machine.machineRef,
      limit: 1, // just fetch latest for now
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !machine.machineRef || !isOperatorTrackingEnabled,
    onCompleted: ({ operatorRuns }) => {
      if (operatorRuns) {
        dispatch(actionSetRecentOperatorRuns(operatorRuns));
      }
    },
  });

  const { refetch: refetchRecentOperatorRuns } = requestRecentOperatorRuns;
  useNetworkError(requestRecentOperatorRuns, ERROR_KEYS.RECENT_OPERATOR_RUNS, {
    pollInterval: THIRTY_SECOND_INTERVAL,
  });

  // we only need to fetch operator list when a run exists on the store but "latestOperator" is falsey - this implies
  // that we do not have any associated data for the operator on that run, and we should fetch a fresh operator list
  // to ensure we have any missing operator data on the store
  const shouldFetchOperatorList = !!(
    isOperatorTrackingEnabled &&
    latestOperatorRun &&
    !latestOperator
  );

  const operatorsQuery = useQuery(GET_OPERATORS, {
    fetchPolicy: 'no-cache',
    skip: !shouldFetchOperatorList,
    onCompleted: ({ Operator: operators }) => {
      dispatch(actionSetOperators(operators));
    },
  });

  useNetworkError(operatorsQuery, ERROR_KEYS.OPERATORS);

  const requestCurrentShiftLegacy = useQuery(GET_CURRENT_SHIFT_LEGACY, {
    variables: {
      machineRef: machine.machineRef,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !machine.machineRef,
    onCompleted: (res) => {
      dispatch(actionSetShifts({ shifts: [res.shifts] }));
    },
  });

  const machineGroupIds = useSelector(getGroupIdsForMachine);

  const erpResourcesQuery = useQuery(GET_ERP_RESOURCES, {
    variables: {
      machineRef: machine.machineRef,
      machineGroups: machineGroupIds,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip:
      !machine.machineRef ||
      !machineGroupIds ||
      (!laborTicketEnabled && !workOrderFlowEnabled),
    onCompleted: (data) => {
      const erpResources = data?.erpResources;
      if (erpResources) {
        const machineErpResource = erpResources.find((resource) => {
          return resource.machineRef === machine.machineRef;
        });

        const associatedResources = erpResources.map((resource) => {
          return resource.resourceId;
        });

        dispatch(
          actionSetMachineERPResources(
            associatedResources,
            machineErpResource?.resourceId || null
          )
        );
      }
    },
  });

  useNetworkError(erpResourcesQuery, ERROR_KEYS.ERP_RESOURCE);

  const hasOpenActivitySet = useSelector(getHasOpenActivitySet);
  const workOrderOpActionLoading = useSelector(getWorkOrderOpActionLoading);
  const laborTicketActionLoading = useSelector(getLaborTicketActionLoading);
  const workOrderOperationRef =
    hasOpenActivitySet && _get(latestActivitySet, 'workOrderOperationRef');

  // request total parts across all activity sets associated to work order op ref
  const requestWorkOrderOperationParts = useQuery(
    GET_WORK_ORDER_OPERATION_PARTS,
    {
      variables: {
        workOrderOperationRef,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip:
        !workOrderOperationRef ||
        !workOrderFlowEnabled ||
        workOrderOpActionLoading ||
        laborTicketActionLoading,
      onCompleted: ({ workOrderOperationParts }) => {
        if (workOrderOperationParts) {
          dispatch(
            actionSetCurrentWorkOrderOpGoodParts(
              workOrderOperationParts.goodParts,
              workOrderOperationParts.startAt
            )
          );
        }
      },
    }
  );

  const {
    refetch: refetchWorkOrderOperationParts,
  } = requestWorkOrderOperationParts;
  useNetworkError(
    requestWorkOrderOperationParts,
    ERROR_KEYS.WORK_ORDER_OPERATION_PARTS,
    {
      pollInterval: POLL_INTERVAL,
    }
  );

  const selectedWorkOrderOpQuery = useQuery(GET_SELECTED_WORK_ORDER_OPERATION, {
    variables: {
      workOrderOperationRef,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !workOrderOperationRef || (!showWorkOrderFlow && !laborTicketEnabled),
    onCompleted: (data) => {
      if (data.erpWorkOrderOperation) {
        const { erpWorkOrderOperation } = data;
        const partOperation = erpWorkOrderOperation.matchingPartOperations[0];
        const partOperationCycleTimeMs = partOperation?.cycleTimeMs || 0;

        const workOrderOperation = {
          ...erpWorkOrderOperation,
          partOperation,
          partOperationRef: partOperation?.partOperationRef,
          // if no operation cycleTimeMs, use cycleTimeMs on partOperation
          cycleTimeMs:
            erpWorkOrderOperation.cycleTimeMs || partOperationCycleTimeMs,
        };
        dispatch(actionSetCurrentWorkOrderOp(workOrderOperation));
      }
    },
  });

  useNetworkError(selectedWorkOrderOpQuery, ERROR_KEYS.CURRENT_WORK_ORDER);

  const { refetch: refetchCurrentShiftLegacy } = requestCurrentShiftLegacy;

  useNetworkError(
    requestCurrentShiftLegacy,
    ERROR_KEYS.RECENT_SHIFT_RUNS_LEGACY,
    {
      pollInterval: THIRTY_SECOND_INTERVAL,
    }
  );

  // Agent Status
  const agentStatus = useQuery(GET_AGENT_STATUS, {
    variables: {
      agentId: machine.agentId,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !machine.agentId,
    onCompleted: (data) => {
      return dispatch(actionSetAgentStatus(data.agentStatus));
    },
  });
  const { refetch: refetchAgentStatus } = agentStatus;
  useNetworkError(agentStatus, ERROR_KEYS.AGENT_STATUS, {
    pollInterval: POLL_INTERVAL,
  });

  // Part Adjustments
  const requestPartAdjustments = useQuery(GET_PART_ADJUSTMENTS, {
    variables: {
      machineId: machine.id,
      start: takeEarliest(scopeStart, setupModeStart),
      end: scopeEnd,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !scopeStart || !scopeEnd || !machine.id || !hasAHistoricalPartsTab,
    onCompleted: ({ getPartAdjustments }) => {
      return dispatch(actionSetPartAdjustments(getPartAdjustments));
    },
  });

  const { refetch: refetchPartAdjustments } = requestPartAdjustments;
  useNetworkError(requestPartAdjustments, ERROR_KEYS.PART_ADJUSTMENTS, {
    pollInterval: POLL_INTERVAL,
  });

  // Part Buckets
  const requestPartBuckets = useQuery(
    GET_PART_BUCKETS_PARTITIONED_BY_SCHEDULED,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        machineRef: machine.machineRef,
        startDate: scopeStart,
        partKeys,
        lastPartTimestamp,
        bucketWidth: hasPartsGoalTab ? '10m' : '60m',
        scheduledIntervals: formatIntervalsAsTSRange(
          partCountQueryIntervals?.scopeScheduledActivityIntervals
        ),
        unscheduledIntervals: formatIntervalsAsTSRange(
          partCountQueryIntervals?.scopeUnscheduledActivityIntervals
        ),
      },
      skip: !isInitialDataSet || !scopeStart || !machine.id || !partKeys,
      onCompleted: ({
        scheduledParts: scheduledPartBuckets,
        unscheduledParts: unscheduledPartBuckets,
      }) => {
        dispatch(
          actionSetPartCountBuckets({
            scheduledPartBuckets,
            unscheduledPartBuckets,
            // track args used for last res so selectors can ignore stale data
            startDate: scopeStart,
          })
        );
      },
    }
  );

  useNetworkError(requestPartBuckets, ERROR_KEYS.PART_BUCKETS);

  // Job Quantity
  const requestJobTotal = useQuery(GET_JOB_QUANTITY, {
    variables: { jobId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !latestJobQuantity,
    onCompleted: ({ jobQuantity }) => {
      dispatch(actionSetJobTotal(jobQuantity.quantity));
    },
  });
  useNetworkError(requestJobTotal, ERROR_KEYS.JOB_QUANTITY, {
    pollInterval: SIXTY_SECOND_INTERVAL,
  });

  // Initial Data
  const requestInitialData = useQuery(GET_INITIAL_DATA, {
    variables: {
      machineRef: machine.machineRef,
      startDate: isLatestShiftActive ? shiftStart : startOfCurrentShopDay,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip:
      isInitialShiftPollLoading ||
      !startOfCurrentShopDay ||
      !machine.id ||
      runLoading ||
      isInitialDataSet,
    onCompleted: ({ executionIntervals, ...data }) => {
      dispatch(actionSetInitialData(data));
      dispatch(actionSetShiftStatusIntervals(executionIntervals));
      dispatch(
        actionSetUtilizationHourlies(
          utilizationSlices(scopeStart, scopeEnd, executionIntervals)
        )
      );
    },
  });

  useNetworkError(requestInitialData, ERROR_KEYS.INITIAL);

  const localDowntimeQueryWindowStart = useMemo(() => {
    if (isLatestShiftActive) {
      return shiftStart;
    }
    return startOfCurrentShopDay;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [shiftStart, startOfCurrentShopDay, isLatestShiftActive]);

  // Downtime
  const downtimeQuery = useQuery(GET_DOWNTIME, {
    fetchPolicy: 'no-cache',
    variables: {
      machineRef: machine.machineRef,
      windowStart: hasDowntimeTab
        ? historyStart
        : localDowntimeQueryWindowStart,
      nonIdleWindowStart: startOfSixDaysPrior,
      // query has max 7 day limit
    },
    skip:
      isInitialShiftPollLoading ||
      !machine.machineRef ||
      !localDowntimeQueryWindowStart ||
      !historyStart,
    onCompleted: ({ downtimes, lastNonIdleInterval }) => {
      const lastNonIdle = lastNonIdleInterval[0] || null;
      dispatch(actionSetDowntimeIntervals(downtimes, lastNonIdle));
    },
  });

  useNetworkError(downtimeQuery, ERROR_KEYS.DOWNTIME);

  const requestWorkflows = useQuery(GET_WORKFLOWS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      dispatch(actionSetWorkflows(data));
    },
  });

  const { refetch: refetchWorkflows } = requestWorkflows;
  useNetworkError(requestWorkflows, ERROR_KEYS.WORKFLOWS, {
    pollInterval: LIGHT_UPDATE_INTERVAL,
  });

  const requestMachineGroups = useQuery(GET_MACHINE_GROUPS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ data }) => {
      dispatch(actionSetMachineGroups(data));
    },
  });

  const { refetch: refetchMachineGroups } = requestMachineGroups;
  useNetworkError(requestMachineGroups, ERROR_KEYS.MACHINE_GROUPS, {
    pollInterval: LIGHT_UPDATE_INTERVAL,
  });

  const requestLabsJwt = useQuery(GET_LABS_JWT, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !LABS_URL || !hasLabTab,
    onCompleted: ({ token }) => {
      try {
        fetch(`${LABS_URL}/auth`, {
          credentials: 'include',
          headers: {
            authorization: `Bearer ${token.token}`,
          },
        }).then((resp) => {
          dispatch(actionSetLabsAuthorized(resp.status === 200));
        });
      } catch (e) {
        bugsnag.notify(e);
      }
    },
  });
  const { refetch: refetchLabsJwt } = requestLabsJwt;
  useNetworkError(requestLabsJwt, ERROR_KEYS.LABS_JWT, {
    pollInterval: LAB_REFRESH_DURATION,
  });

  // scheduled time
  const requestScheduledTime = useQuery(GET_SCHEDULED_TIME, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip:
      !scheduledTimeEnabled || !machine.machineRef || !scopeStart || !scopeEnd,
    variables: {
      machineRef: machine.machineRef,
      startAt: scopeStart,
      endAt: takeEarliest(
        scopeEnd,
        moment
          .utc()
          .startOf('minute')
          .toISOString()
      ),
      shouldRound: 1,
    },
    onCompleted: (data) => {
      dispatch(
        actionSetScheduledTime({
          scheduledTime: data.getScheduledTime,
        })
      );
    },
  });

  const { refetch: refetchScheduledTime } = requestScheduledTime;

  const refetchScheduledTimeEnabled = () => {
    return dispatch(
      actionSetScheduledTimeEnabled({
        isEnabled: scheduledTimeEnabled,
      })
    );
  };

  const refetch = useCallback(() => {
    dispatch(actionSetLoading());
    Promise.all([
      refetchRecentActivitySets(),
      refetchAgentStatus(),
      refetchRecentOperatorRuns(),
      requestCurrentShiftLegacy(),
      refetchPartAdjustments(),
      refetchWorkflows(),
      refetchLabsJwt(),
      refetchMachineGroups(),
      refetchScheduledTime(),
      refetchScheduledTimeEnabled(),
    ]).then(() => {
      return dispatch(actionUnsetLoading());
    });
  }, [
    dispatch,
    refetchAgentStatus,
    refetchRecentActivitySets,
    refetchRecentOperatorRuns,
    refetchCurrentShiftLegacy,
    refetchWorkOrderOperationParts,
    refetchPartAdjustments,
    refetchWorkflows,
    refetchLabsJwt,
    refetchMachineGroups,
    refetchScheduledTime,
  ]);

  return { refetch };
};

export { useInitialData };
